@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CatalogWidget {
    &-Item {
        padding: 0 25px 0 0;
    }

    &-NoProducts {
        font-size: 12px;
        background: $color-neutral-20;
        height: 190px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 44px;
    }

    &-Loader {
        height: 190px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.RecommendedCatalogWidget {
    padding: 20px 0;
    z-index: 1;

    .TypographyHeader {
        text-align: center;
        margin-top: 0;
    }
}

@include mobile {
    .CatalogWidget {
        &-Item {
            width: 145px !important; //Needed to override inline JS-generated style
        }
    }
}

@include tablet {
    .CatalogWidget {
        &-Item {
            width: 350px !important; //Needed to override inline JS-generated style
        }

        &-NoProducts, &-Loader {
            height: 442px;
        }
    }
}

@include mobileAndTablet {
    .RecommendedCatalogWidget {
        margin-right: -20px;
    }
}

@include tabletAndAbove {
    .CatalogWidget {
        margin-bottom: 30px;
    }
}

@include desktopAndAbove {
    .CatalogWidget {
        &-Item {
            padding: 0 10px 0;
        }

        &-NoProducts, &-Loader {
            height: 445px;
        }

        .slick-slider {
            margin-left: -10px;
            margin-right: -10px;
        }

        .slick-list {
            overflow: visible;
        }

        .SliderPrevArrow, .SliderNextArrow {
            height: 100%;
            bottom: 0;
            transform: none;
            display: flex;
            align-items: center;
            width: 35px;
            background: $color-neutral-5;
        }

        .SliderPrevArrow {
            left: -34px;
        }

        .SliderNextArrow {
            justify-content: flex-end;
            right: -34px;
        }
    }
}

